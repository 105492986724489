import React, { Component } from 'react';
import Layout from '../../../components/Layout';
import PageHeading from '../../../components/PageHeading/PageHeading';

class CurriculaKnight extends Component {
  render() {
    return (
      <Layout>
        <PageHeading>Hiep Si Curriculum</PageHeading>
        <h1>HS curricula</h1>
      </Layout>
    );
  }
}

export default CurriculaKnight;
